import React, { useState, useEffect } from 'react';
import { PopupConfirm } from '@components/Popup';
import { useDispatch } from 'react-redux';
import { deleteGuestUser, removeGuestFromMembers } from '@actions';

export function DeleteGuestUserPopup({selectedGuest, exitOnClickHandler}){

    const dispatch = useDispatch();
    const [hasConfirmed, setHasConfirmed] = useState(false);
    
    useEffect(() => {
        if(hasConfirmed){
            dispatch(deleteGuestUser(selectedGuest.id));
            dispatch(removeGuestFromMembers(selectedGuest.id));
            onCancelClick();
        }
    }, [hasConfirmed]);

    const onCancelClick = () => {
        setHasConfirmed(false)
        exitOnClickHandler();
    };

    if(!selectedGuest){
        return null;
    }

    return (
        <PopupConfirm 
            onConfirmHandler={() => setHasConfirmed(true)}
            onCancelHandler={onCancelClick}
            message={`Are you sure you want to delete the guest "${selectedGuest.first_name} ${selectedGuest.last_name}"?`} />
    );
}
