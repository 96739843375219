import React from 'react';
import useSwr from 'swr';
import axiosTransport from '../actions/axios';

const fetcher = (url) => axiosTransport.get(url).then(({data}) => data);

export function useMember(id){
  const { data: member, error } = useSwr(`/users/${id}`, fetcher);

  return {
    member,
    isLoading: !member && !error,
    isError: error
  };
}