import React from 'react';
import Layout from '@components/layout';
import { Router } from '@reach/router';
import SecuredComponent from '@components/secured';
import Members from '@components/members';
import { Member } from '@components/Member';
import { useAuth } from '@hooks/useAuth';
import { SystemNotification } from '@components/SystemNotification';

function AppPage(){
    
    const auth = useAuth();

    return (
        <Layout>
            <SystemNotification />
            <Router basepath='/app'>
                <SecuredComponent component={Members} path="/members" />
                <SecuredComponent component={Member} path="/members/:id" />
            </Router>
        </Layout>
    );
};

export default AppPage;
