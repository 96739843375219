import React, { useState, useEffect } from 'react';
import { Popup, PopupConfirm } from '@components/Popup';
import { useSelector, useDispatch } from 'react-redux';
import { searchForFullMembers, transferFromGuestToMember } from '@actions';
import UserAvatar from '@components/user_avatar';
import { Button } from '@components/Button';
import './MergeGuestPopup.scss';
import { resetTransferStatus, TRANSFER_SUCCESS } from '../../actions';

function UserToCheckin({user, buttonLabel, onClickHandler}){

    return (
        <div className="checkin__user">
            <UserAvatar profile_image_url={user.profile_image_thumbnail} is_guest={user.is_guest} />
            <div className="user__name">{user.full_name}</div>
            <button className="form__button form__button--secondary__btn" onClick={onClickHandler}>{buttonLabel}</button>
        </div>
    );
}

export function MergeGuestUserPopup({isOpen, selectedGuest, exitOnClickHandler}){

    const dispatch = useDispatch();
    const fullMembers = useSelector(state => state.fullMembers);
    const transferFromGuestStatus = useSelector(state => state.transferFromGuestStatus);
    const errorMessage = useSelector(state => state.errorMessage);
    const [name, setName] = useState('');
    const [selectedMember, setSelectedMember] = useState(null);
    const [mergeStarted, setMergeStarted] = useState(false);
    const [canSubmit, setCanSubmit] = useState(false);
    const [hasConfirmed, setHasConfirmed] = useState(false);
    
    useEffect(() => {
        if(canSubmit && hasConfirmed){
            dispatch(transferFromGuestToMember(selectedGuest.id, selectedMember.id));
            // setCanSubmit(false);
            // setHasConfirmed(false);
        }
    }, [canSubmit, hasConfirmed]);

    useEffect(() => {
        if(transferFromGuestStatus === TRANSFER_SUCCESS){
            setCanSubmit(false);
            setHasConfirmed(false);
            onClickHander();
            dispatch(resetTransferStatus());
        }
    }, [transferFromGuestStatus]);

    const handleSubmit = (e) => {
        e.preventDefault();
        setSelectedMember(null);
        dispatch(searchForFullMembers(name));
    };

    const onClickHander = () => {
        setName('');
        setSelectedMember(null);
        exitOnClickHandler();
    };

    const selectMemberOnClick = (member) => {
        if(selectedMember && (member.id === selectedMember.id)){
            return setSelectedMember(null);
        }

        setSelectedMember(member);
    };

    const mergeGuestBtnOnClickHandler = () => {
        setCanSubmit(true);
    };

    if(!isOpen || !selectedGuest){
        return null;
    }

    return (
        <>
            {canSubmit && !hasConfirmed ? 
                <PopupConfirm 
                    onConfirmHandler={() => setHasConfirmed(true)}
                    onCancelHandler={() => setCanSubmit(false)}
                    message="Are you sure you want to merge this guest with this member account?" /> : null}
            <Popup title="Merge Guest" exitOnClickHandler={onClickHander}>
                <div className="merge__guest">
                    <div className="merge__guest__from">Merge guest <b>{selectedGuest.first_name} {selectedGuest.last_name}</b> with an existing member account</div>
                    
                    {!selectedMember ? (
                        <>
                        <div className="merge__guest__note">
                            <p>Use the search below to find the member account you want to transfer the guests' Check-in and payment records to.</p>
                        </div>
                        <form onSubmit={handleSubmit} className="merge__guest__form">
                            <div className="form__row__group">
                                
                                <div className="form__row">
                                    <label className="form__label" htmlFor="name">Search Members</label>
                                    <input id="name" className="form__input" type="text" name="name" placeholder="Enter name" value={name} onChange={(e) => {setName(e.target.value)}} />
                                </div>
                                <div className="form__row form__row--submit">
                                    <button className="form__button form__button--primary__btn">Search</button>
                                </div>
                            </div>
                        </form>
                        </>
                    ) : 
                    <div className="merge__guest__note">
                        <p>You are about to transfer check-in and payment records to the member below.</p>
                    </div>
                    }
                    
                    {fullMembers && !selectedMember ? 
                        <div className="members__list">
                            {fullMembers.map((user, idx) => <UserToCheckin key={idx} user={user} buttonLabel="Select" onClickHandler={() => selectMemberOnClick(user)} />)}
                        </div> : null}
                    {selectedMember ? <UserToCheckin key={0} user={selectedMember} buttonLabel="Remove" onClickHandler={() => selectMemberOnClick(selectedMember)} /> : null}
                    {(selectedMember || hasConfirmed) ? 
                        <Button 
                            label="Merge records"
                            onClick={mergeGuestBtnOnClickHandler} 
                            isWorking={hasConfirmed}
                            className="form__button form__button--primary__btn form__button--merge-guest" /> 
                        : null}
                </div>
                
            </Popup>
        </>
    );
}
