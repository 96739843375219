import { Link } from 'gatsby';
import React from 'react';
import UserIcon from '../icons/user';

const UserImage = ({currentUser, name}) => {
    return (
        <img className="checkin__image" src={currentUser.profile_image_small} alt={name} title={name} />
    );
};

const PlaceholderImage = () => {
    return (
        <div className="checkin__image checkin__image--placeholder">{UserIcon}</div>
    );
};

export function SelectedMember({
    member,
    showName = true,
    showProfileLink = true,
    deleteGuestUserAction = () => { },
}){

    if(!member){
        return null;
    }
        
    const CheckinImage = ({currentUser, name}) => {
        return currentUser.profile_image_small ? <UserImage currentUser={currentUser} name={name} /> : <PlaceholderImage />
    };

    return (
        <div className="latest__checkin">
            <CheckinImage currentUser={member} name={member.full_name} />
            <div className="checkin__details">
                {showName ? <div className="checkin__name">{member.full_name}</div> : null}
                <div className="checkin__date">{member.entry_date_label}</div>
                <div className="checkin__user__actions">
                    {showProfileLink ? <Link to={`/app/members/${member.id}`} className="action__btn">View Profile</Link> : null}
                    {member.is_guest ? <a onClick={deleteGuestUserAction} className="action__btn">Delete Guest</a> : null}
                </div>
            </div>
        </div>
    );
}

export default SelectedMember;