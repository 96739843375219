import React, { useState } from 'react';
import { Popup } from '@components/Popup';
import { useSelector, useDispatch } from 'react-redux';
import {
    createGuestUser
} from '../actions';
import { FormErrorLabel } from './forms';

function AddGuestUser({exitHandlerOnClick}){

    const dispatch = useDispatch();
    const formErrors = useSelector(state => state.formErrors);
    const [fullName, setFullName] = useState('');
    
    const onSubmitHandler = (e) => {
        e.preventDefault();
        dispatch(createGuestUser(fullName));
    };

    return (
        <Popup title="Add guest user" className="popup__inner--add__guest__user" exitOnClickHandler={exitHandlerOnClick}>
            <div className="create__payment__container">
                <div className="create__payment__component create__payment__component--payment__details">
                    <h2 className="create__payment__component__title">Add guest user</h2>
                    <form onSubmit={onSubmitHandler}>

                        <div className="form__row">
                            <label className="form__label" htmlFor="full_name">Full Name</label>
                            <input id="full_name" name="full_name" className="form__input" placeholder="Enter full name" value={fullName} onChange={(e) => setFullName(e.target.value)} />
                            <FormErrorLabel fieldKey="full_name" formErrors={formErrors} />
                        </div>
                        <button type="submit" className="action__btn action__btn--primary__btn action__btn--se">Submit</button>
                    </form>
                </div>
            </div>
        </Popup>
    );
}

export default AddGuestUser;