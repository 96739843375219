import React, { useEffect, useState } from 'react';
import { SEO } from '@components/SEO';
import { MenuComponent } from '@components/Menu';
import UserAvatar from '@components/user_avatar';
import { useSelector, useDispatch } from 'react-redux';

import { PopupConfirm } from '@components/Popup';
import TakePaymentPopup from '@components/take_payment_popup';
import AssignDiscountPopup from '@components/assign_discount_popup';
import ReactTooltip from 'react-tooltip';
import CardIcon from '../icons/card';
import CashIcon from '../icons/cash';
import StripeIcon from '../icons/stripe';

import { 
    takePayment,
    exitTakePayment,
    exitAssignDiscount,
    assignDiscount,
} from '@actions';
import { useMember } from '@hooks/useMember';
import SelectedMember from '../SelectedMember/SelectedMember';

const paymentMethods = {
    'cash': CashIcon,
    'card': CardIcon,
    'stripe': StripeIcon
};

const SimpleComponent = ({title, children, ...rest}) => {
        
    return (
        <div {...rest} className="component">
            <h2 className="component__header">{title}</h2>
            <div className="component__body">
                {children}
            </div>
        </div>
    );
};

const CheckinRow = ({ checkin }) => {
    return (
        <>
            <div className={`table__row`}>
                <div className="table__column column__name">
                    <div className="user__name">{checkin.entry_date}</div>
                </div>
            </div>
        </>
    );
};

const PaymentRow = ({payment}) => {

    const membershipPrice = payment.membership_type_price_with_discount ? `£${payment.membership_type_price_with_discount} / ${payment.discount_name} - ${payment.discount_amount}%` : `£${payment.membership_type_price}`

    return (
        <div className={`table__row`}>
            <div className="table__column column__date">{payment.payment_date_label}</div>
            <div className="table__column column__name">
                <UserAvatar profile_image_url={payment.profile_image_thumbnail} />
                <div className="user__name"><b>{payment.user_full_name}</b><br />Recorded by {payment.admin_user_full_name}</div>
            </div>
            <div className="table__column column__payment__info">
                <div className="membership__type">
                    <div className="membership__type__method">{paymentMethods[payment.payment_method]}</div>
                    <div className="membership__type__label">{payment.membership_type_name} ({membershipPrice})</div>    
                </div>
                
            </div>
            <div className="table__column column__payment__dates">
                <div className="payment__dates">
                    <b>From:</b> {payment.payment_from_label}<br />
                    <b>To:</b> {payment.payment_to_label}
                </div>
            </div>
        </div>
    );
};

const GuestRow = ({ guest, member, triggerUndoConfirmation }) => {
    return (
        <>
            <div className={`table__row`}>
                <div className="table__column column__name">
                    <div className="user__name">{guest.full_name}</div>
                </div>
                <div className="table__column column__name">
                    <div className="user__name">{guest.full_name}</div>
                </div>
                <div className="table__column column__undo">
                    <div className="undo">
                        <button type="button" className="action__btn action__btn--danger" onClick={() => triggerUndoConfirmation(guest.id)(member.profile.id)}>Undo</button>
                    </div>
                </div>
            </div>
        </>
    );
};

const EmptyRow = ({children}) => {

    return (
        <>
            <div className={`table__row`}>
                <div className="table__column column__name">
                    <div className="user__name">{children}</div>
                </div>
            </div>
        </>
    );
};

export function Member({id}){
    const dispatch = useDispatch();

    const { member, isLoading, isError } = useMember(id);
    const isTakingPayment = useSelector(state => state.isTakingPayment);
    console.log('isTakingPayment: ', isTakingPayment);
    
    const [isTriggeringUndo, setIsTriggeringUndo] = useState(false);
    const takePaymentOnClickHandler = () => dispatch(takePayment());
    const handleExitTakePaymentOnClick = () => dispatch(exitTakePayment());

    const assignDiscountOnClickHandler = () => dispatch(assignDiscount());
    const handleExitAssignDiscountOnClick = () => dispatch(exitAssignDiscount());

    if(isLoading){
        return 'Loading...';
    }

    if(isError){
        return 'Error loading profile ' + isError;
    }

    const triggerUndoConfirmation = (guestId) => {
        return (memberId) => {
            console.log(guestId, memberId);
            setIsTriggeringUndo(true);
        };
    };

    const onUndoConfirmHandler = () => {
        setIsTriggeringUndo(false);
    };

    const onUndoCancelHandler = () => {
        setIsTriggeringUndo(false);
    };
    
    return (
        <>
            <ReactTooltip place="bottom" type="light" effect="solid" className="react__tooltip__custom" />
            <SEO title="Members" />
            <div className="main__layout">
                <div className="left__column">
                    <MenuComponent />
                </div>
                <div className="middle__column">
                    <div className="component__wrapper">
                        <div className="component__inner">
                        <div className="component__wrapper__header">
                                <h1>{member.profile.full_name}</h1>
                                <div className="component__wrapper__actions">
                                    <button className="action__btn action__btn--white-primary" onClick={takePaymentOnClickHandler}>
                                        <span className="action__btn__label">Take a payment</span>
                                        <span className="action__btn__label action__btn__label--mobile">Pay</span>
                                    </button>
                                    <button className="action__btn action__btn--white-primary" onClick={assignDiscountOnClickHandler}>Assign Discount</button>
                                </div>
                            </div>
                            <div className="component__container">
                                <SimpleComponent title="Check-ins">
                                    <div className="component__content__wrapper">
                                        {member.checkins.length ? member.checkins.map((checkin) => <CheckinRow checkin={checkin} />) : <EmptyRow>No check-ins found.</EmptyRow>}
                                    </div>
                                </SimpleComponent>  
                            </div>
                        </div>
                    </div>

                    <div className="component__wrapper">
                        <div className="component__inner">
                            <div className="component__container">
                                <SimpleComponent title="Payments">
                                    <div className="component__content__wrapper">
                                        {member.payments.length ? member.payments.map((payment) => <PaymentRow payment={payment} />) : <EmptyRow>No payments found.</EmptyRow>}
                                    </div>
                                </SimpleComponent>
                            </div>
                        </div>
                    </div>

                    <div className="component__wrapper">
                        <div className="component__inner">
                            <div className="component__container">
                                <SimpleComponent title="Merged Guests">
                                    <div className="component__content__wrapper">
                                        {member.merged_guests.length ? member.merged_guests.map((guest) => <GuestRow guest={guest} member={member} triggerUndoConfirmation={triggerUndoConfirmation} />) : <EmptyRow>No merged guests found.</EmptyRow>}
                                    </div>
                                </SimpleComponent>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="right__column">
                    <SelectedMember 
                        member={member.profile}
                        showName={false}
                        showProfileLink={false}
                        />
                </div>
            </div>    
            {isTakingPayment ? 
                <TakePaymentPopup 
                    userId={member.profile.id} 
                    userName={`${member.profile.full_name}`} 
                    userDiscount={{
                        name: member.profile.discount_name,
                        amount: member.profile.discount_amount
                    }}
                    exitOnClickHandler={handleExitTakePaymentOnClick}
                /> : null}
            {isTriggeringUndo ?
                <PopupConfirm
                    message="Do you want to undo this guest merge?"
                    onConfirmHandler={onUndoConfirmHandler}
                    onCancelHandler={onUndoCancelHandler}
                /> : null}
        </>
    );
}

export default Member;