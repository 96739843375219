import React, { useEffect, useState } from 'react';
import { SEO } from '@components/SEO';
import { MenuComponent } from '@components/Menu';
import UserAvatar from '../components/user_avatar';
import { useSelector, useDispatch } from 'react-redux';
import { 
    getMembers,
    takePayment,
    exitTakePayment,
    exitAssignDiscount,
    assignDiscount,
    openAddGuestUser,
    exitAddGuestUser,
    openMergeGuestUser,
    exitMergeGuestUser
} from '../actions';
import TakePaymentPopup from './take_payment_popup';
import AssignDiscountPopup from './assign_discount_popup';
import AddGuestUserPopup from './add_guest_user_popup';
import { MergeGuestUserPopup } from './MergeGuestPopup';
import ReactTooltip from 'react-tooltip';
import { SelectedMember } from '@components/SelectedMember';
import { DeleteGuestUserPopup } from './DeleteGuestUserPopup';

const SimpleComponent = ({title, children, ...rest}) => {
        
    return (
        <div {...rest} className="component">
            <h2 className="component__header">{title}</h2>
            <div className="component__body">
                {children}
            </div>
        </div>
    );
};

const MemberRow = ({ member, selectedUser, setSelectedUser }) => {

    const isSuperAdmin = (member.is_superadmin) ? (
        <div className="user__label user__label--admin">Superadmin</div>
    ) : null;

    const isAdmin = (member.is_admin) ? (
        <div className="user__label user__label--admin">Admin</div>
    ) : null;

    const isGuest = (member.is_guest) ? (
        <div className="user__label user__label--guest">Guest</div>
    ) : null;

    const checkinLabel = member.last_entry_paid ? 'Paid' : 'Not Paid';
    const checkinSubscriptionStatus = member.last_entry_paid ? 'green' : 'red';
    const isSelected = selectedUser && selectedUser.id === member.id;

    const handleRowOnClick = () => {
        
        if(selectedUser && (selectedUser.id === member.id)){
            setSelectedUser(null);
        }else{
            setSelectedUser(member);
        }
        
    };

    return (
        <div className={`table__row ${isSelected ? 'table__row--selected' : ''}`} onClick={handleRowOnClick}>
            <div className="table__column column__name">
                <UserAvatar profile_image_url={member.profile_image_thumbnail} is_guest={member.is_guest} />
                <div className="user__name">{member.first_name} {member.last_name} {isSuperAdmin} {isAdmin} {isGuest}</div>
            </div>
        </div>
    );
};

const MembersFilter = ({members, selectedLetter, selectedLetterOnClickHandler}) => {

    if(!members){
        return null;
    }

    return (
        <div className="members__filter">
            <a className={`member__filter__btn member__filter__btn--all ${!selectedLetter ? ' member__filter__btn--active' : ''}`} href="#members__list" onClick={() => selectedLetterOnClickHandler(null)}>ALL</a>
            {members.map((group, idx) => {
                return (
                    <a key={idx} className={`member__filter__btn ${selectedLetter === group.letter ? ' member__filter__btn--active' : ''}`} href={`#members__list__${group.letter}`} onClick={() => selectedLetterOnClickHandler(group.letter)}>{group.letter}</a>
                );
            })}
        </div>
    );
};

MembersFilter.displayName = 'Test Component';

function Members(){
    const dispatch = useDispatch();
    const currentGym = useSelector(state => state.currentGym);
    const members = useSelector(state => state.members);
    const isAddingGuestUser = useSelector(state => state.isAddingGuestUser);
    const isMergingGuestUser = useSelector(state => state.isMergingGuestUser);
    const isTakingPayment = useSelector(state => state.isTakingPayment);
    const [isDeletingGuestUser, setIsDeletingGuestUser] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    
    useEffect(() => {
        dispatch(getMembers());
    }, [dispatch]);

    useEffect(() => {
        if(members){
            setSelectedUser(null);
        }
    }, [members]);

    const openAddGuestUserOnClick = () => dispatch(openAddGuestUser());
    const exitAddGuestUserOnClick = () => dispatch(exitAddGuestUser());

    const openMergeGuestUserOnClick = () => dispatch(openMergeGuestUser());
    const exitMergeGuestUserOnClick = () => dispatch(exitMergeGuestUser());

    const takePaymentOnClickHandler = () => dispatch(takePayment());
    const handleExitTakePaymentOnClick = () => dispatch(exitTakePayment());

    const assignDiscountOnClickHandler = () => dispatch(assignDiscount());
    const handleExitAssignDiscountOnClick = () => dispatch(exitAssignDiscount());

    if(!currentGym){
        return null;
    }
    
    return (
        <>
            <ReactTooltip place="bottom" type="light" effect="solid" className="react__tooltip__custom" />
            <SEO title="Members" />
            <div className="main__layout">
                <div className="left__column">
                    <MenuComponent />
                </div>
                <div className="middle__column">
                    <div className="component__wrapper">
                        <div className="component__inner">
                            <div className="component__wrapper__header">
                                <h1>Members</h1>
                                <div className="component__wrapper__actions">
                                    <button 
                                        className="action__btn action__btn--white" 
                                        onClick={openAddGuestUserOnClick}
                                        data-tip="Add guest account for use at your gym only"
                                    >Add Guest</button>
                                    <button 
                                        className="action__btn action__btn--white" 
                                        disabled={!selectedUser || !(selectedUser && selectedUser.is_guest)} 
                                        onClick={openMergeGuestUserOnClick}
                                        data-tip="Merge a guest account with an existing member account"
                                    >Merge Guest</button>
                                    <button className="action__btn action__btn--white" disabled={!selectedUser} onClick={takePaymentOnClickHandler}>
                                        <span className="action__btn__label">Take a payment</span>
                                        <span className="action__btn__label action__btn__label--mobile">Pay</span>
                                    </button>
                                    <button className="action__btn action__btn--white" disabled={!selectedUser} onClick={assignDiscountOnClickHandler}>Assign Discount</button>
                                </div>
                            </div>

                            <div className="component__container">
                                <a id="members__list"></a>
                                {(members && members.length) ? members.map((group, idx) => {
                                    return (
                                        <SimpleComponent key={idx} id={`members__list__${group.letter}`} title={group.letter}>
                                            <div className="data__table">
                                                <div className="table__row table__header">
                                                    <div className="table__column column__name">Name</div>
                                                </div>
                                                {group.members && !group.members.length ? <div className="table__row"><div className="table__column">No members found...</div></div> : null}
                                                {group.members.map((member, idx) => <MemberRow key={member.id} member={member} selectedUser={selectedUser} setSelectedUser={setSelectedUser} />)}
                                            </div>
                                        </SimpleComponent>
                                    )
                                }) : null}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="right__column">
                    <SelectedMember 
                        member={selectedUser}
                        showName={true}
                        deleteGuestUserAction={() => setIsDeletingGuestUser(true)}
                        />
                </div>
            </div>
            {isAddingGuestUser ? 
                <AddGuestUserPopup 
                    exitHandlerOnClick={exitAddGuestUserOnClick}
                /> : null }
            <MergeGuestUserPopup 
                isOpen={isMergingGuestUser}
                selectedGuest={selectedUser}
                exitOnClickHandler={exitMergeGuestUserOnClick}
            />
            {selectedUser ? 
                <AssignDiscountPopup 
                    userId={selectedUser.id} 
                    userName={`${selectedUser.first_name} ${selectedUser.last_name}`} 
                    userDiscount={{
                        name: selectedUser.discount_name,
                        amount: selectedUser.discount_amount
                    }}
                    exitOnClickHandler={handleExitAssignDiscountOnClick}
                /> : null}
            {selectedUser && isTakingPayment ? 
                <TakePaymentPopup 
                    userId={selectedUser.id} 
                    userName={`${selectedUser.first_name} ${selectedUser.last_name}`} 
                    userDiscount={{
                        name: selectedUser.discount_name,
                        amount: selectedUser.discount_amount
                    }}
                    exitOnClickHandler={handleExitTakePaymentOnClick}
                /> : null}
            {selectedUser && isDeletingGuestUser ?
                <DeleteGuestUserPopup
                    selectedGuest={selectedUser}
                    exitOnClickHandler={() => setIsDeletingGuestUser(false)}
                /> : null}
            
        </>
    );
}

export default Members;